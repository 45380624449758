<template>
    <div class="screenBox">
        <div class="TopTitle">
            馆藏文物预防性保护风险防控管理服务平台
        </div>
        <div class="MainBox">
            <div class="sideBox">
                <div class="leftTop">
                    <div class="sideTitle">全国中心概述</div>
                    <div class="containBox LT-box">
                       <div v-if="overview" v-html="overview.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"></div>
                    </div>
                </div>
                <div class="leftBottom">
                    <div class="sideTitle">全国中心概览</div>
                    <div class="containBox LB-box">
                        <div class="container">
                            <pieChart title="全国区域中心占比" :data="areaPie"/>
                            <div class="buttomCharts"></div>
                        </div>
                        <div class="container">
                            <pieChart title="全国博物馆占比" :data="museumPie"/>
                            <div class="buttomCharts" ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="centerBox">
                <mapCenter :areaName="areaData"/>
            </div>
            <div class="sideBox">
                <div class="rightTop">
                    <div class="sideTitle">文物风险状态占比</div>
                    <div class="containBox RT-box">
                        <barChart :barxAxis="barxAxis" :barData="barData"/>
                    </div>
                </div>
                <div class="rightBottom">
                    <div class="sideTitle">环境指标超限时长对比</div>
                    
                    <div class="containBox RB-box">
                        <div class="operBar">
                            <el-radio-group v-model="typeSener">
                                <el-radio-button label="温度" />
                                <el-radio-button label="湿度" />
                                <el-radio-button label="光照" />
                                <el-radio-button label="紫外" />
                            </el-radio-group>
                        </div>
                        <div class="RB-chart">
                            <warningBar/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mapCenter from './Modules/Map.vue'
import pieChart from './Modules/3Dpie.vue'
import barChart from './Modules/bar.vue'
import warningBar from './Modules/warningBar.vue'
import { country_listZone, country_Area } from '@/api/countryScreen.js'
export default{
    components:{
        mapCenter,
        pieChart,
        barChart,
        warningBar
    },
    data(){
        return{
            overview:'',
            areaPie:[{
                  name: '已接入',//名称
                  value: 0,//值
                  itemStyle: {//颜色 紫色
                    color: 'rgba(36, 212, 235,1)'
                  }
              }, {
                  name: '未接入',//红色
                  value: 0,
                  itemStyle: {
                    color: 'rgba(255, 83, 99,1)',
                  }
              }],
            museumPie:[
            {
                  name: '已接入',//名称
                  value: 0,//值
                  itemStyle: {//颜色 紫色
                    color: 'rgba(36, 212, 235,1)'
                  }
              }, {
                  name: '未接入',//红色
                  value: 0,
                  itemStyle: {
                    color: 'rgba(255, 83, 99,1)',
                  }
              }
            ],
            areaData:[],
            typeSener:'温度', 
            barxAxis:[],
            barData:{
                excellent:[],
                good:[],
                average:[],
                poor:[]
            }
        }
    },
    mounted(){
        this.getMsg()
    },
    methods:{
        getMsg(){
            country_listZone().then(r=>{
                let data = r.data
                this.overview = data.overview
                let stats = data.stats.split(',')
                let areaStats = stats[0].split(':')
                this.areaPie[0].value = Number(areaStats[0])
                this.areaPie[1].value = Number(areaStats[1])
                let museumStats = stats[1].split(':')
                this.museumPie[0].value = Number(museumStats[0]) 
                this.museumPie[1].value = Number(museumStats[1])

                this.areaData = data.children.map(i=>{
                    let obj = {
                        name: i.name,
                        value: [i.lon, i.lat, i.children.length],
                        code:i.code
                    }
                    return  obj
                })
            })
            this.barxAxis = []
            this.barData = {excellent:[],good:[],average:[],poor:[]}
            country_Area().then(r=>{
                let data = r.data
                for(let key in data){
                    this.barxAxis.push(key)
                    this.barData['excellent'].push(data[key].excellent)
                    this.barData['good'].push(data[key].good)
                    this.barData['average'].push(data[key].average)
                    this.barData['poor'].push(data[key].poor)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.screenBox{
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    box-sizing: border-box;
    background-image: url('../../assets/screenCountry/screenBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
}
.TopTitle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: url('../../assets/screenCountry/topBg.png');
    background-size:contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 30px;
    color: #ffffff;
    line-height: 80px;
    text-align: center;
    font-weight: 500;
}
.MainBox{
    width: 100%;
    height: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
    .sideBox{
        width: 420px;
        height: 100%;
    }
    .centerBox{
        width: calc(100% - 860px);
        height: 100%;
    }

}
.leftTop{
    width: 100%;
    height: 30%;
    .LT-box{
        text-align:justify;
        text-justify:inter-word;
        text-indent: 2rem;
        font-size: 14px;
        line-height: 24px;
    }
}
.leftBottom{
    width: 100%;
    height: calc(70% - 30px);
    margin-top: 20px;
    .LB-box{

    }
}


.rightTop{
    width: 100%;
    height: 40%;
    .RT-box{

    }
}
.rightBottom{
    width: 100%;
    height: calc(60% - 30px);
    margin-top: 20px;
    .RB-box{
        .operBar{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .RB-chart{
            width: 100%;
            height: calc(100% - 80px);
        }
    }
    
}
.sideTitle{
    width: 100%;
    height: 40px;
    background-image: url('../../assets/screenCountry/titleBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 40px;
}
.containBox{
    width: calc(100% - 30px);
    margin: 0 auto;
    height: calc(100% - 40px);
    background-color: #00000013;
    border: 1px solid #2ba3ff21;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
}

 //饼图(外面的容器)
.container{
    width: 100%;
    height: 50%;
}
//饼图底座（我也想给你们底座图片 可是我不知道咋给）
.buttomCharts{
    background: center top url('../../assets/screenCountry/chartbg.png') no-repeat;
    background-size: contain;
    height: 140px;
    width: 100%;
    margin-top: -175px;
}

</style>