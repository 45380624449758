import request from '@/utils/request'

export function country_listZone() {
  return request({
    url: '/center/data/listZone',
    method: 'get'
  })
}

export function country_Area() { // 区域风险占比
  return request({
    url: '/site/data/countArea',
    method: 'get'
  })
}
