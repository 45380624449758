<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import chinaJson from '@/utils/china.json'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      areaName: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null,
      }
    },
    watch:{
      'areaName':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
    //   this.__resizeHandler = debounce(() => {
    //     if (this.chart) {
    //       this.chart.resize()
    //     }
    //   }, 500)
    //   window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          echarts.registerMap("china", chinaJson)
          this.chart = markRaw(echarts.init(this.$el))
          this.chart.off('click')
          this.chart.on('click',  (params)=> {
            const { href } = this.$router.resolve({
                name: "areaScreen",
                query: {
                    id: params.data.code
                }
            });
            window.open(href, '_blank');
          })
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            visualMap: {
                min: 0,
                max: 10,
                show: true,
                inRange: {
                  color: ['#eac736', '#6EAFE3'].reverse()
                },
            },
            geo3D: {
              type:'map3D',
              map: 'china',
              viewControl: {
                center: [0, 0, 0],
                alpha: 40,//上下旋转角度
                beta: 10,//左右旋转角度
                animation: true,//是否动画显示
                animationDurationUpdate: 1000,//动画时间
                distance: 130,//视角到主题距离
                minBeta: -9999,//最小（左）旋转度数
                maxBeta: 9999,//最多（右）旋转角度
                autoRotate: false,
                autoRotateDirection: 'cw',
                autoRotateSpeed: 10,
             },
              light: {
                main: {
                  intensity: 1.2,
                  color: '#0D3867',
                  shadowQuality: 'ultra',
                  shadow: true,
                  alpha: 150,
                  beta: 200
                },
                ambient: {
                  intensity: 1,//环境光强度
                },
                ambientCubemap: {
                  diffuseIntensity: 1,
                  texture: ''
                }
              },
              groundPlane: {
                show: false
              },
              postEffect: {
                enable: false
              },
              itemStyle: {
                color: '#175096',
                borderColor: 'rgb(62,215,213)',
                opacity:0.8,//透明度
                borderWidth: 1,
              },
              label: {
                show: false
              },
              emphasis: {
                label: {
                  show: false
                },
                itemStyle: {
                }
              },
              silent: false, // 不响应和触发鼠标事件
            },
            series:[
              {
                  type: 'bar3D',
                  coordinateSystem: 'geo3D',
                  bevelSize: 0,
                  minHeight: 1,
                  barSize: 2,
                  itemStyle: {
                      color: '#17e3ff',
                      opacity: 1
                  },
                  label: {
                      show: true,
                      distance: 0,
                      formatter: function (params) {
                          var name = params.name;
                          var value = params.value[2];
                          return `{a|${name}}\n{b|${value}}`;
                      
                      },
                      textStyle: {
                          backgroundColor: {
                              image: require('/public/6UJEopkTH.png'),
                          },
                          padding: [16, 20],
                          color: '#E59B0A',
                          fontSize: 12,
                          height: 40,
                          lineHeight: 20,
                          rich: {
                              a: {
                                  color: '#E59B0A',
                                  fontSize: 12,
                                  align: 'center',
                              },
                              b: {
                                  color: '#0EB1FE',
                                  fontSize: 14,
                                  fontWeight: 600,
                                  align: 'center',
                              },
                          },
                      },
                  },
                  emphasis: {
                    itemStyle: {
                        color: '#e46318',
                    },
                  },
                  shading: 'lambert',
                  data:this.areaName ,
              },
            ]
            
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>